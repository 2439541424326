import React from "react"
import Layout from "../components/layout.js"
import ImageContainer from "../components/imageContainer"
import OvalGreen from "../images/ovalGreen.png"
import ButtonHb from "../components/buttonHB"

const Style = {
  BgMain: {
    all: "absolute w-full flex justify-end",
  },
  BigOval: {
    all: "hidden sm:block sm:w-96 sm:pl-20 md:pl-5 lg:w-97 xl:w-100 xl:pl-2",
  },
  OvalGreenDiv: {
    all: "overflow-hidden transform scale-y-inv w-48 h-32 pl-8 sm:hidden",
  },
  OvalGreenImg: {
    all: "w-full h-64  object-cover object-left-bottom",
  },
  DownloadSectionMain: {
    all:
      "pt-24 sm:pt-16 relative container  px-5 sm:px-6 md:px-6 lg:pt-24 lg:px-24 xl:px-20",
  },
  DownloadSectionDiv: {
    all:
      "flex justify-between  w-full mt-12 sm:mt-5 sm:w-6/12 sm:flex-col lg:w-7/12",
  },
  DownloadSectionTitle: {
    all: "frk-bold text-primary",
    small: "w-7/12 sm:w-8/12 md:w-10/12 mt-4 text-2xl sm:text-3xl sm:mt-0",
    large: "lg:w-auto lg:text-5xl xl:text-6xl",
  },
  DownloadSectionLink: {
    all:
      "bg-aqua rounded-full flex items-center justify-center hover:bg-tertiary",
    large: "w-32 h-32   sm:my-6  lg:my-10  lg:w-40 lg:h-40 xl:w-48 xl:h-48 ",
  },
  PdfIcon: {
    all: "w-8 px-1 lg:w-12  mx-auto",
  },
  DownloadSectionText: {
    all: "frk-bold text-sm my-2 sm:text-lg sm:my-3",
  },
  DownloadIcon: {
    all: "w-5 lg:w-10 lg:px-1 mx-auto",
  },
  FirstSection: {
    small: "container  px-5 mt-12 sm:px-6  sm:mt-8 md:px-6",
    large: "lg:px-24 xl:px-20  lg:flex lg:justify-between lg:mt-24 lg:mb-12",
  },
  LeftSectionMain: {
    all: "w-full lg:w-8/12 lg:pr-2",
  },
  LeftSectionMap: {
    all: "w-full text-primary mt-6",
  },
  LeftSectionMapTitle: {
    all: "frk-bold text-2xl my-1"
  },
  LeftSectionMapText: {
    all: "frk-regular text-lg"
  },
  CommonNeedMap: {
    all: "sm:flex mt-6"
  },
  CommonNeedMapImg: {
    all: "w-48 pr-5 mb-8 sm:mb-0 sm:pr-0 md:w-3/12 md:pr-2 lg:w-5/12 lg:pr-2 xl:pr-0 xl:w-3/12"
  },
  CommonNeedMapTextDiv: {
    all: "text-primary w-full sm:w-9/12"
  },
  CommonNeedMapTitle: {
    all: "frk-bold text-lg mb-1 "
  },
  CommonNeedMapText: {
    all: "frk-regular text-lg"
  },
  RightSectionMain: {
    all: "w-full mt-3 flex justify-end lg:mt-0 lg:w-5/12",
  },
  RightSectionOrg: {
    all: "w-full lg:w-11/12 mt-6 flex flex-col text-white bg-primary",
  },
  BlogoIcon: {
    all: "mt-6 px-4 w-3/12  sm:w-16 sm:px-0 sm:pl-4",
  },
  RightSectionDecoBgMain: {
    all: "w-full flex justify-end items-end flex-1",
  },
  RightSectionDecoOvalGreen: {
    all: "w-2/12 pl-3 mr-2  mb-20 sm:pl-20 lg:pl-8 sm:mr-0 sm:mb-16 lg:mb-12",
  },
  RightSectionDecoOvalBlue: {
    all: "overflow-hidden w-16 h-40 sm:w-32 sm:h-24",
  },
  RightSectionDecoOvalBlueImg: {
    all: "w-full h-56 sm:h-56 object-cover object-left-bottom",
  },
  RightSectionMap: {
    all: "w-full px-4 mt-3 lg:mt-6 ",
  },
  RightSectionMapTitle: {
    all: "frk-bold text-2xl my-1",
  },
  RightSectionMapText: {
    all: "frk-regular text-lg"
  },
  MiddleSectionMain: {
    all: "bg-opaque",
  },
  MiddleSectionDiv: {
    all: "w-full container pb-1 pt-6 mt-10 px-5",
    small: "sm:px-6 sm:pt-6 md:px-6 sm:flex sm:flex-wrap sm:justify-between",
    large: "lg:px-24 lg:py-12 xl:px-20",
  },
  MiddleSectionMap: {
    all: "flex flex-col w-full mb-8 pb-4 lg:mb-12 xl:mb-0 sm:w-31p bg-white",
  },
  ImageMap: {
    all: `w-full mb-3  bg-tertiary  flex items-center`,
  },
  MiddleSectionTitle: { 
    all: "frk-bold text-2xl w-full mb-8" 
  },
  MiddleSectionMapTitle: {
    all: "text-primary text-lg frk-bold px-4 my-1"
  },
  MiddleSectionMapText: {
    all: "frk-regular text-lg px-4"
  },
  LastSectionMain: {
    all: "w-full  container",
    small: "py-8 px-5 sm:px-6 md:px-6 lg:px-24 xl:px-20   sm:py-8 sm:flex sm:flex-wrap sm:justify-between",
    large: "lg:py-20"
  },
  LastSectionMap: {
    all: "shadow flex flex-col w-full mb-5 sm:mt-0 sm:w-31p "
  },
  LastSectionImgCard:{
    small: `w-full hidden sm:block`,
    large: `w-full sm:hidden`
  },
  LastSectionMapTitle: {
    all: "text-primary frk-bold text-lg px-4 sm:px-2 lg:text-2xl lg:px-4 my-2"
  },
  LastSectionMapText: {
    all: "frk-regular text-sm px-4 sm:px-2 lg:text-lg lg:px-4",
  },
  LastSectionMapButtonDiv: {
    all: `w-7/12 sm:w-8/12 md:w-7/12 h-auto flex flex-1 self-end lg:w-6/12`
  },
  LastSectionMapButton: {
    all: " mr-4 my-3 w-full flex-1 self-end sm:mr-2 lg:mr-4"
  }
} 

const CommonNeedsCont = ({ Cont }) =>
  Cont.map((el, ind) => {
    let imgPosition =
      el.imagePos === "left" ? "sm:flex-row" : "sm:flex-row-reverse"
    let margin = el.imagePos === "left" ? "sm:pl-6" : "sm:pr-6"
    return (
      <div className={`${Style.CommonNeedMap.all} ${imgPosition}`} key={`CNC_${ind}`}>
        <ImageContainer
          container={Style.CommonNeedMapImg.all}
          src={require(`../images/${el.imageSrc}`)}
          alt={el.imageSrc}
        />
        <div className={`${Style.CommonNeedMapTextDiv.all} ${margin}`}>
          <h3 className={Style.CommonNeedMapTitle.all}>{el.title}</h3>
          <p className={Style.CommonNeedMapText.all}>{el.description}</p>
        </div>
      </div>
    )
  })

const LeftSectionCont = ({ Cont }) =>
  Cont.map((element, index) => (
    <div className={Style.LeftSectionMap.all} key={`LFS_${index}`}>
      <h3 className={Style.LeftSectionMapTitle.all}>{element.title}</h3>
      <p className={Style.LeftSectionMapText.all}>{element.description}</p>
      <CommonNeedsCont Cont={element.content} />
    </div>
  ))

const RightSectionCont = ({ Cont }) =>
  Cont.map((element, index) => (
    <div className={Style.RightSectionMap.all} key={`RS_${index}`}>
      <h3 className={Style.RightSectionMapTitle.all}>{element.title}</h3>
      <p className={Style.RightSectionMapText.all}>{element.description}</p>
    </div>
  ))
//w-full container pb-1 pt-6 mt-10 px-5 sm:px-6 sm:pt-6 md:px-6 xl:flex xl:flex-wrap sm:justify-between  lg:px-24 lg:py-12 xl:px-20"
const MiddleSection = ({ Content }) =>
  Content.middleSection && Content.middleSection.length > 0 ? (
    <section
      className={`${Style.MiddleSectionDiv.all} ${Style.MiddleSectionDiv.small} ${Style.MiddleSectionDiv.large}`}
    >
      <h3 className={Style.MiddleSectionTitle.all}>Technologies</h3>
      <MiddleSectionCont Cont={Content.middleSection} />
    </section>
  ) : (
    ""
  )

const MiddleSectionCont = ({ Cont }) =>
  Cont.map((element, index) => (
    <div className={Style.MiddleSectionMap.all} key={`MD_${index}`}>
      <ImageContainer
        container={Style.ImageMap.all}
        src={require(`../images/${element.imageSrc}`)}
        alt={element.imageSrc}
      />
      <h3 className={Style.MiddleSectionMapTitle.all}>{element.title}</h3>
      <p className={Style.MiddleSectionMapText.all}>{element.description}</p>
    </div>
  ))

const LastSection = ({ Cont }) =>
  Cont.map((element, index) => (
    <div
      className={Style.LastSectionMap.all}
      key={`LS_${index}`}
    >
      <a href={element.url}>
        <ImageContainer
          container={Style.LastSectionImgCard.small}
          src={require(`../images/${element.imageSrc}`)}
          alt={Cont.imageSrc}
        />
        <ImageContainer
          container={Style.LastSectionImgCard.large}
          src={require(`../images/${element.imageSrcMb}`)}
          alt={Cont.imageSrc}
        />
      </a>
      <h3 className={Style.LastSectionMapTitle.all}>
        {element.title}
      </h3>
      <p className={Style.LastSectionMapText.all}>
        {element.description}
      </p>
      <div
        className={Style.LastSectionMapButtonDiv.all}
      >
        <ButtonHb
          type="link"
          text="Read More"
          className={Style.LastSectionMapButton.all}
          to={element.url}
        />
      </div>
    </div>
  ))

const OurWorkPageTemplate = props => {
  const Content = props.pageContext.page
  return (
    <Layout link={`/solutions${Content.path}`} title={Content.title}>
      <div className={`${Style.BgMain.all}`}>
        <ImageContainer
          container={`${Style.BigOval.all}`}
          src={require(`../images/${Content.background}`)}
          alt={Content.background}
        />
        <ImageContainer
          container={Style.OvalGreenDiv.all}
          src={OvalGreen}
          alt="OvalGreen"
          className={Style.OvalGreenImg.all}
        />
      </div>
      <section className={Style.DownloadSectionMain.all}>
        <div className={Style.DownloadSectionDiv.all}>
          <h1
            className={`${Style.DownloadSectionTitle.all} ${Style.DownloadSectionTitle.small} ${Style.DownloadSectionTitle.large}`}
          >
            {Content.title}
          </h1>
          <a
            href={require(`../static/${Content.pdfLink}`)}
            download={`${Content.title} - HelloBuild`}
            className={`${Style.DownloadSectionLink.all} ${Style.DownloadSectionLink.large}`}
          >
            <div>
              <ImageContainer
                container={`${Style.PdfIcon.all}`}
                src={require(`../images/pdf.png`)}
                alt="pdf"
              />
              <p className={Style.DownloadSectionText.all}>DOWNLOAD</p>
              <ImageContainer
                container={Style.DownloadIcon.all}
                src={require(`../images/download.png`)}
                alt="download"
              />
            </div>
          </a>
        </div>
      </section>
      <section
        className={`${Style.FirstSection.small} ${Style.FirstSection.large}`}
      >
        <div className={Style.LeftSectionMain.all}>
          <LeftSectionCont Cont={Content.leftSection} />
        </div>
        <div className={Style.RightSectionMain.all}>
          <div className={Style.RightSectionOrg.all}>
            <ImageContainer
              container={Style.BlogoIcon.all}
              src={require(`../images/bhellobuild.png`)}
              alt="bhellobuild.png"
            />
            <RightSectionCont Cont={Content.rightSection} />
            <div className={Style.RightSectionDecoBgMain.all}>
              <ImageContainer
                container={Style.RightSectionDecoOvalGreen.all}
                src={require(`../images/ovalGreen.png`)}
                alt="ovalGreen.png"
              />
              <ImageContainer
                container={Style.RightSectionDecoOvalBlue.all}
                src={require(`../images/ovalBlue.png`)}
                alt="ovalBlue.png"
                className={Style.RightSectionDecoOvalBlueImg.all}
              />
            </div>
          </div>
        </div>
      </section>
      <div className={Style.MiddleSectionMain.all}>
        <MiddleSection Content={Content} />
      </div>
      <section className={`${Style.LastSectionMain.all} ${Style.LastSectionMain.small} ${Style.LastSectionMain.large}`}>
        <LastSection Cont={Content.lastSection} />
      </section>
    </Layout>
  )
}

export default OurWorkPageTemplate
